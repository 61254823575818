import { RouteInfo } from './vertical-menu.metadata';
import {ConstantsProvidersAdmin} from '../../services/constantsAdmin';

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: '/page', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],idpm: 'dash'
  },
  {
    path: '/page/monitoreo', title: 'Monitoring', icon: 'fa fa-desktop', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'managment'
  },
  {
    path: '/page/cabbooking/p/g', title: 'Telephonist', icon: 'fa fa-headphones', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'telephonycab'
  },
  {
    path: '', title: 'Search', icon: 'fa fa-binoculars', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, idpm: 'mngfind',
    submenu: [
                { path: '/page/searchservice', title: 'History', icon: 'fa fa-history', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],idpm: 'findservice' },
                { path: '/page/searchscheduled', title: 'Schedule', icon: 'fa fa-clock-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],idpm: 'findschedule' },
    ]
  },
  {
    path: '', title: 'ControlFleet', icon: 'fa fa-tachometer', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, idpm: 'mngfleet',
    submenu: [
      { path: '/page/fleets', title: 'CtrlFleet', icon: 'fa fa-map', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],idpm: 'ctrlflota' },
      { path: '/page/chat', title: 'MessageFleet', icon: 'fa fa-wechat', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],idpm: 'msgflota' },
      { path: '/page/drivers', title: 'DriverAuth', icon: 'fa fa-drivers-license-o', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [],idpm: 'driverlogin' },
    ]
  },
  {
    path: ConstantsProvidersAdmin.SERVERWSAPPADMIN, title: 'Administration', icon: 'fa fa-archive', class: '', isExternalLink: true, submenu: [], idpm: 'admingx'
  },
  {
    path: '/page/statistics', title: 'Estadísticas', icon: 'fa fa-line-chart', class: '', isExternalLink: false, submenu: [], idpm: 'statistics'
  },
];

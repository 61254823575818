export class ConstantsProviders {

  public static PROTOCOL = 'http://';
  public static PORT_WSHTTP = ':80';
  public static PROTOCOLHTTPS = 'https://';
  public static PORT_WSHTTPS = ':443';

  // public static SERVER_URL = 'webcoreecotaxi.als.com.uy'; // srv desarrollo
  public static SERVER_URL = 'webcoreecotaxi.fleet.uy'; // srv Produccion

  public static SERVER_WS: string = ConstantsProviders.PROTOCOLHTTPS
    + ConstantsProviders.SERVER_URL + ConstantsProviders.PORT_WSHTTPS;
  // public static SERVER_WS: string = ConstantsProviders.PROTOCOL
  // + ConstantsProviders.SERVER_URL + ConstantsProviders.PORT_WSHTTP;
  public static APPGATEWAY = '/gotaxi-gateway-web/webresources/';

  public static URIFOTOCHOFER = 'https://www.fleet.uy/fotoschoferes/140191640013/'; // es el rut lo que va despues del fotoschoferes
  public static EXTFILECHOFER = '.jpg';

  // WS Gateway
  public static BASEWS = 'clientwebfleet/';
  public static WSGETCLIENT = ConstantsProviders.BASEWS + 'getClient/';
  public static WSGETSERVICESMONITOR = ConstantsProviders.BASEWS + 'getallservicesmonitor/';
  public static WSGETALLVEHICLE = ConstantsProviders.BASEWS + 'getfleet';
  public static WSGETALLVEHICLEONLINE = ConstantsProviders.BASEWS + 'getVehicleOnLine';
  public static WSNEWSERVICE = ConstantsProviders.BASEWS + 'putservice/';
  public static WSSENDMESSAGE = ConstantsProviders.BASEWS + 'sendMsg/';
  public static WSGLOBAL = ConstantsProviders.BASEWS + 'getglobal/';
  public static WSCANCELSERVICE = ConstantsProviders.BASEWS + 'cancel_service/';
  public static WSTYPEPAY = 'gestion/getAllTypePayment/'; // es el ws en despacho relacionado a la admin
  public static WSSTATISTICS = ConstantsProviders.BASEWS + 'getglobalestatistics/';
  public static WSSEARCHSERVICEHIST = ConstantsProviders.BASEWS + 'getservicehistory/';
  public static WSSEARCHSCHEDULED = ConstantsProviders.BASEWS + 'getsearchscheduled/';
  public static WSLISTVEHICLEONLINESTATUS = ConstantsProviders.BASEWS + 'getListVehicleStatusOnLine/';
  public static WSPUTASSINGVEHICLE = ConstantsProviders.BASEWS + 'putassingvehicle/';
  public static WSAUTHUSER = ConstantsProviders.BASEWS + 'logingxadmin/';
  public static WSLASTPOST = ConstantsProviders.BASEWS + 'getlastposvehicle/';
  public static WSLOGOUTDRIVER = ConstantsProviders.BASEWS + 'logoutdrivervehi/';
  public static WSLISTDRIVERS = ConstantsProviders.BASEWS + 'getdrivers/';
  public static WSRESCHEDULER = ConstantsProviders.BASEWS + 'rescheduleservice/';
  public static WSHOLD = ConstantsProviders.BASEWS + 'holdeservice/';
  public static WSGETALLUSER = 'getalluser/';
  public static WSGETTRAKING = ConstantsProviders.BASEWS + 'trackingvehicle/';
  public static WSGETTRAKINGSERVICE = ConstantsProviders.BASEWS + 'trackingservice/';

  public static WSCHANGEPASSWORD = 'changepassword/';
  public static WSGETPROFILE = 'getprofile/';

  // MapsQuest
  public static myKeyMapQuest     = '7qHhkR1iyeObbMQgEV63YMNJeM7orTj3'; // para EcoTaxiRivera
  public static urlMapQuest       = 'https://www.mapquestapi.com/geocoding/v1/address?key=';

  // PubNub
  public static publishkey   = 'pub-c-25169dc3-bcbd-4fff-a6d0-a1873e5b903b';             // PROD
  public static subscribekey = 'sub-c-f0184dd0-934b-11e4-b395-02ee2ddab7fe';             // PROD
  public static secretkey    = 'sec-c-MTRkOWVmMmUtODIyOC00YjJlLWI1NjYtYzAwMzg3NjdlZTg5'; // PROD

  // constantes por defecto para latitud y longitud
  public static LATDEFAULT = -30.8999187;
  public static LNGDEFAULT = -55.5399061;
  public static ZOOMDEFAULT = 16;

  // constantes por defecto para creacion de direccion para latitud y longitud
  // y se usa para el mapa que muestra la flota tambien
  public static LATADDRESSDEFAULT = -30.8999187;
  public static LNGADDRESSDEFAULT = -55.5399061;


}

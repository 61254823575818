<nav class="navbar navbar-expand-lg navbar-light header-navbar {{transparentBGClass}}"
     [ngClass]="{'navbar-brand-center': menuPosition === 'Top', 'navbar-static': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Static' , 'navbar-sticky': menuPosition === 'Side' &&
  config?.layout.navbar.type === 'Fixed', 'fixed-top': isSmallScreen && menuPosition === 'Top'}">
  <div class="container-fluid navbar-wrapper">
    <div class="navbar-header d-flex">
      <div class="navbar-toggle menu-toggle d-xl-none d-block float-left align-items-center justify-content-center"
           data-toggle="collapse" (click)="toggleSidebar()">
        <i class="ft-menu font-medium-3"></i>
      </div>
      <ul class="navbar-nav" (clickOutside)="toggleSearchOpenClass(false)">
        <li class="nav-item mr-2 d-none d-lg-block">
          <a class="nav-link apptogglefullscreen" id="navbar-fullscreen" href="javascript:;" appToggleFullscreen
             (click)="ToggleClass()"><i class=" {{toggleClass}} font-medium-3"></i></a>
        </li>

        <li>
          <i class="fa fa-plug text-success" *ngIf="this.webSocketBk.conectado"></i>
          <i class="fa fa-plug text-danger" *ngIf="!this.webSocketBk.conectado"></i>
        </li>

      </ul>
      <div class="navbar-brand-center">
        <div class="navbar-header">
          <ul class="navbar-nav">
            <li class="nav-item">
              <div class="logo"><a class="logo-text" [routerLink]="['/']" *ngIf="config?.layout.menuPosition === 'Top'">
                <div class="logo-img"><img class="logo-img" alt="Fleet Logo" [src]="logoUrl"></div><span
                class="text">APEX</span>
              </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="navbar-container">
      <div class="collapse navbar-collapse d-block" id="navbarSupportedContent">
        <ul class="navbar-nav">
          <li class="i18n-dropdown dropdown nav-item mr-2" display="dynamic" ngbDropdown >
            <a class="nav-link d-flex align-items-center dropdown-toggle dropdown-language" id="dropdown-flag"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle><img class="langimg selected-flag"
                                                                                 [src]="selectedLanguageFlag" alt="flag" /><span
              class="selected-language d-md-flex d-none">{{selectedLanguageText}}</span></a>
            <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" aria-labelledby="dropdown-flag">
              <a class="dropdown-item" href="javascript:;" data-language="en" (click)="ChangeLanguage('en')">
                <img
                  class="langimg mr-2" src="./assets/img/flags/us.png" alt="flag" /><span
                class="font-small-3">English</span></a><a class="dropdown-item" href="javascript:;" data-language="es"
                                                          (click)="ChangeLanguage('es')"><img class="langimg mr-2" src="./assets/img/flags/es.png"
                                                                                              alt="flag" /><span class="font-small-3">Spanish</span></a>
            </div>
          </li>

          <li class="dropdown nav-item mr-1" placement="bottom-left" display="static" ngbDropdown >
            <a class="nav-link dropdown-toggle user-dropdown d-flex align-items-end" id="dropdownBasic2"
               href="javascript:;" data-toggle="dropdown" ngbDropdownToggle>
              <div class="user d-md-flex d-none mr-2">
                <span class="text-right">{{getNameUser()}}</span><span
                class="text-right text-muted font-small-3">{{"Available" | translate}}</span>
              </div>
              <img class="avatar" src="assets/img/portrait/small/user.png" alt="avatar" height="35" width="35" />
            </a>
            <div class="dropdown-menu text-left dropdown-menu-right m-0 pb-0" aria-labelledby="dropdownBasic2"
                 ngbDropdownMenu>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" (click)="logout()">
                <div class="d-flex align-items-center">
                  <i class="ft-power mr-2"></i><span>{{"Logout" | translate}}</span>
                </div>
              </a>
            </div>
          </li>

        </ul>
      </div>
    </div>
  </div>
</nav>

import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';

export const HROUTES: RouteInfo[] = [
  {
    path: '/page', title: 'Page', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: [], idpm: 'dash'
  },
  {
    path: '/page/monitoreo', title: 'Page', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: [], idpm: 'managment'
  },
  {
    path: '/page/cabbooking/p/g', title: 'Page', icon: 'ft-home', class: 'dropdown nav-item', isExternalLink: false, submenu: [], idpm: 'telephonycab'
  },
  {
    path: '', title: 'Find', icon: 'ft-align-left', class: 'dropdown nav-item has-sub', badge: '', badgeClass: '', isExternalLink: false, idpm: 'mngfind',
    submenu: [


                { path: '/page/searchservice', title: 'Third Level 1.1', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'findservice' },
                { path: '/page/searchscheduled', title: 'Third Level 1.2', icon: 'ft-arrow-right submenu-icon', class: 'dropdown-item', badge: '', badgeClass: '', isExternalLink: false, submenu: [], idpm: 'findservice' },


    ]
},
];

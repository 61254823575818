export class ConstantsProvidersAdmin {

  public static PROTOCOL_ADMIN = 'http://';
  public static PORT_WSHTTP_ADMIN = ':80';

  public static PROTOCOLHTTPS_ADMIN = 'https://';
  public static PORT_WSHTTPS_ADMIN = ':443';

  // public static SERVER_URL_ADMIN = 'webcoreecotaxi.als.com.uy'; // Desarrollo
  public static SERVER_URL_ADMIN =  'webcoreecotaxi.fleet.uy'; // Prod

  public static SERVER_WS_ADMIN: string = ConstantsProvidersAdmin.PROTOCOL_ADMIN
    + ConstantsProvidersAdmin.SERVER_URL_ADMIN + ConstantsProvidersAdmin.PORT_WSHTTP_ADMIN;
  public static BASEAPPADMINWS = '/gotaxi-admin/';
  public static SERVERWSAPPADMIN = ConstantsProvidersAdmin.SERVER_WS_ADMIN
    + ConstantsProvidersAdmin.BASEAPPADMINWS;
  public static CHANGEPASSWORD_ADMIN = '/gotaxi-online/rest/new_pass';
  // public static APPADMIN = '/gotaxi-admin/servlet/';
  // public static URIAPPADMIN = '/gotaxi-admin/rest/';
  // public static APPDATACLIENT_ADMIN = 'get_dataClient2';
  // public static GETPROFILELOGIN_ADMIN = '/gotaxi-admin/rest/get_profile2/';

}


import {Client} from '@stomp/stompjs';
import * as SockJS from 'sockjs-client';
import {IStompSocket} from '@stomp/stompjs/esm6';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {of} from 'rxjs/observable/of'

@Injectable({
  providedIn:'root',
})
export class WebSocketAPI {

  // private urlServer = 'https://devceleritas.als.com.uy/gotaxi-gateway-web-ws/';
  private urlServer = 'http://localhost:8080/';
  private webSocketEndPoint: string = this.urlServer+'dispatch-websocket';
  private client?: Client;
  conectado = false;
  clienteId?: string;

  constructor() {
    this.clienteId = 'id-' + new Date().getTime() + '-' + Math.random().toString(36).substring(2);
    this.client = new Client();
    this.client.webSocketFactory = () => {
      return new SockJS(this.webSocketEndPoint) as IStompSocket;
    }
  }

  init() {
    this.client.onConnect = (frame) => {
      console.log('Conectados: ' + this.client!.connected + ' : ' + frame);
      this.conectado = true;
    }

    this.client!.onDisconnect = (frame) => {
      console.log('Desconectados: ' + !this.client!.connected + ' : ' + frame);
      this.conectado = false;
    }
  }

  subscribe(topic: string, callback: any): void {
    const connected: boolean = this.client!.connected;
    if (connected) {
      this.subscribeToTopic(topic, callback);
      return;
    }

    console.log('Service WebSocket no conect');
    this.init();
    this.connect();
    setTimeout(()=> {
      this.subscribeToTopic(topic, callback);
    },1000);
  }

  private subscribeToTopic(topic: string, callback: any): void {
    this.client!.subscribe(topic,(message): any => {
      callback(message);
    });
  }
  connect() {
    this.client!.activate();
  }

  disconnect() {
    this.client!.deactivate().then(r => {});
  }

  sendMessage() {
    this.client!.publish({destination:'/app/statusdispacht'});
  }
}

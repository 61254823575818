import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {CryptoService} from '../crypto/crypto.service';
import {ConstantsProvidersAdmin} from '../constantsAdmin';
import {ConstantsProviders} from '../constants';
import {UserVOGX} from '../../models/user/UserVOGX';
import {UserVO} from '../../models/user/UserVO';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  usuariosId = '';
  usuarioDTO;

  constructor( public http: HttpClient, public _crypto: CryptoService) {
    // console.log('En el constructor del Service ' + ' variable static de Constants: ' + ConstantsProviders.SERVER_WS);
    this.cargarStorage();
  }

  private getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({'Content-Type': 'application/json; charset=utf-8'});
    return headers;
  }

  guardarStorage( id: string, roles: string ) {
    localStorage.setItem('uf', this._crypto.encrypted(id));
    localStorage.setItem('fp', this._crypto.encrypted(roles));
  }

  cargarStorage() {

    if ( localStorage.getItem('uf')) {
      this.usuariosId = this._crypto.decrypted(localStorage.getItem('uf'));
      this.usuarioDTO = JSON.parse(this._crypto.decrypted(localStorage.getItem('fp').trim()).trim());
    } else {
      this.usuariosId = '';
    }
  }

  estaLogueado() {
    // console.log('Largo: ', this.usuariosId.length);
    return ( this.usuariosId.length > 1 ) ? true : false;
  }

  getLogin(username: string, password: string) {

    return new Promise((resolve) => {
      const body = '{\"userName\": \"' + username + '\" , \"userPassword\": \"' + password + '\" }';
      // const url: string = ConstantsProvidersAdmin.SERVER_WS_ADMIN + ConstantsProvidersAdmin.GETPROFILELOGIN_ADMIN;
      const url: string = ConstantsProviders.SERVER_WS + ConstantsProviders.APPGATEWAY + ConstantsProviders.WSAUTHUSER;
      // console.log( 'URL: ' + url );
      // console.log( 'Josn: ' + body);
      this.http.post(url.trim(), body.trim() , {headers: this.getHeaders()})
        .subscribe((data) => {

          //esto es temoral hasta ver en gx
          // const dataLuis = '{"empresa":{"rut":"140191640013","tipoempresadesc":"TAXIS","tipoempresaid":"1"},"loginerror":{"Code":"0","Message":""},"programs":{"programname":[]},"programs_additional":{"programname":[]},"programs_dispatch":{"programname":["ctrlflota","driverlogin","findschedule\\t","findservice","managment","mngfind","mngfleet","msgflota","telephonycab","dash","admingx","statistics"]},"role":"OwnerCab"}';
          // resolve((JSON.parse(dataLuis)));
          resolve(data);

        }, err => {
          console.log(err);
        });
    });
  }

  getPrfile(user: UserVO) {
    return new Promise((resolve) => {
      const body = JSON.stringify(user);
      const url: string = ConstantsProviders.SERVER_WS + ConstantsProviders.APPGATEWAY + ConstantsProviders.BASEWS
          + ConstantsProviders.WSGETPROFILE;
      this.http.post(url.trim(), body.trim() , {headers: this.getHeaders()})
          .subscribe((data) => {
            resolve(data);
          }, err => {
            // console.log(err);
          });
    });
  }

  ChangePassword(user: UserVO) {
    return new Promise((resolve) => {
      const body = JSON.stringify(user);
      const url: string = ConstantsProviders.SERVER_WS + ConstantsProviders.APPGATEWAY + ConstantsProviders.BASEWS
          + ConstantsProviders.WSCHANGEPASSWORD;
      this.http.post(url.trim(), body.trim() , {headers: this.getHeaders()})
          .subscribe((data) => {
            resolve(data);
          }, err => {
            // console.log(err);
          });
    });
  }

  ChangePasswordUserGX(user: UserVOGX) {
    return new Promise((resolve) => {
      const body = JSON.stringify(user);
      const url: string = ConstantsProvidersAdmin.SERVER_WS_ADMIN + ConstantsProvidersAdmin.CHANGEPASSWORD_ADMIN;
      // console.log('url gx ' + url);
      this.http.post(url.trim(), body.trim() , {headers: this.getHeaders()})
          .subscribe((data) => {
            resolve(data);
          }, err => {
            // console.log(err);
          });
    });
  }

  getUsuariosId() {
    return this.usuariosId.trim();
  }

  setUsuariosId(pUsuariosId) {
    this.usuariosId = pUsuariosId.trim();
  }

  getUserCorpAll(user) {
    return new Promise((resolve) => {
      const body = JSON.stringify(user);
      const url: string = ConstantsProviders.SERVER_WS + ConstantsProviders.APPGATEWAY + ConstantsProviders.BASEWS
          + ConstantsProviders.WSGETALLUSER;
      this.http.post(url.trim(), body.trim() , {headers: this.getHeaders()})
          .subscribe((data) => {
            resolve(data);
          }, err => {
            // console.log(err);
          });
    });
  }
}

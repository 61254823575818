import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import {ROUTES} from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import {AuthService} from '../../services/auth/auth.service';
import {RouteInfo} from './vertical-menu.metadata';

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/img/logecotaxirivera.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    public auth: AuthService
  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }


  ngOnInit() {
    // this.menuItems = ROUTES;
    this.switchMenu();
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();
      this.cdr.detectChanges(); // Forzar la detección de cambios aquí
    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  switchMenu() {
    const role: string = this.auth.usuarioDTO.role;
    const permisos = this.auth.usuarioDTO.programs_dispatch.programname;
    // console.log('Role:', role); // Registro del rol
    // console.log('Permissions:', permisos); // Registro de permisos

    if (role.trim().toUpperCase() === 'FLEET') {
      this.menuItems = this.filterMenuByPermissions(ROUTES, permisos);
    } else {
      switch (role) {
        case 'OwnerRemisTel': {
          // console.log('OwnerRemisTel');
          this.menuItems = []; // Añade aquí la lógica para este rol si es necesario
          break;
        }
        case 'Operator': {
          // console.log('Operator');
          this.menuItems = this.filterMenuByPermissions(ROUTES, permisos);
          break;
        }
        case 'OperatorCab': {
          // console.log('OperatorCab');
          this.menuItems = this.filterMenuByPermissions(ROUTES, permisos);

          break;
        }
        case 'OwnerCab': {
          // console.log('OwnerCab');
          // this.menuItems = this.filterMenuByPermissions(ROUTES, permisos);
          this.menuItems = ROUTES;
          break;
        }
        default: {
          // console.log('Role not recognized');
          this.menuItems = []; // Fallback
          break;
        }
      }
    }
    // console.log('Menu Items:', this.menuItems); // Registro de los elementos del menú
    this.cdr.detectChanges();
  }


  filterMenuByPermissions(menu: RouteInfo[], permissions: string[]): RouteInfo[] {
    // console.log(JSON.stringify(menu));
    // console.log(JSON.stringify(permissions));
    return menu.filter(item => {
      if (permissions.includes(item.idpm)) {
        if (item.submenu && item.submenu.length > 0) {
          item.submenu = this.filterMenuByPermissions(item.submenu, permissions);
        }
        return true;
      }
      return false;
    });
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
      if (this.resizeTimeout) {
          clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout((() => {
        this.innerWidth = event.target.innerWidth;
          this.loadLayout();
      }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      // this.menuItems = ROUTES;
      this.switchMenu();
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/img/logecotaxirivera.png';
    }

    if(this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
